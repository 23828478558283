div .outer-container {
  font-family: "Lato", sans-serif;
  overflow: hidden;
  min-height: 100vh;
}

.modal-content {
  background-color: transparent;
  border-width: 0px;
}

.custom-copyright {
  color: white;
}

#email::placeholder,
#password::placeholder,
#confirmationCode::placeholder,
#deviceId::placeholder,
#deviceName::placeholder,
#deviceDescription::placeholder,
#confirmPassword::placeholder {
  color: white;
}

.navbar-custom {
  background-color: white;
  margin: 0;
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  border-radius: 0;
  color: black;
  box-shadow: 5px 5px 17px -4px rgba(199, 199, 199, 0.6);
}
.navbar-brand-custom {
  height: 100%;
  display: flex;
  align-items: center;
  width: 200px;
  color: black;
  padding-left: 30px;
}

.navbar-brand-custom:hover {
  color: black;
}

.nav-button-custom {
  transition-duration: 0.3s;
  font-size: 17px;
  font-weight: 100;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-button-custom:hover {
  cursor: pointer;
  transition-duration: 0.4s;
  background-color: #ededed;
  padding-right: 60px;
  padding-left: 60px;
}

.basiccard-wrapper {
  width: 100%;
  padding: 5px;
  white-space: pre-wrap;
  color: white;
}
.basiccard-container {
  padding: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  border-width: 0px;
  border-style: solid;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}

.hover-container:hover {
  border-color: #a8eb12;
  border-style: solid;
  border-width: 2;
  cursor: pointer;
}
.basiccard-header {
  font-size: small;
  font-weight: 100;
  padding: 5px;
}

.basiccard-body {
  font-size: xx-large;
  font-weight: 300;
  padding: 5px;
}

.thumbnail-wrapper {
  width: 100%;
  height: 100px;
  margin: 5px;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.thumbnail-container {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.thumbnail-image {
  max-width: 100%;
  max-height: 100%;
}

.thumbnail-caption {
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 200;
  height: 10%;
}

rect.bordered {
  stroke: #e6e6e690;
  stroke-width: 1px;
}

.heatmap-card {
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
}

.line-chart-wrapper {
  width: 100%;
  position: relative;
}

.simple-line-chart-wrapper {
  box-shadow: 0 0 15px #dedede;
  padding: 10px;
}

.line-chart-wrapper-loading {
  position: absolute;
  right: 0;
  top: 0;
  width: 75px;
  height: 75px;
  background-color: white;
  border-radius: 100px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.5);
  transition-duration: 0.4s;
  margin: 10px;
  padding: 15px;
}

.line-chart-wrapper-loading-small {
  position: absolute;
  right: 0;
  top: 0;
  width: 55px;
  height: 55px;
  background-color: white;
  border-radius: 100px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.5);
  transition-duration: 0.4s;
  margin: 10px;
  padding: 15px;
}

.calendar-icon {
  width: 50px;
  height: 50px;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
  border-color: #ededed40;
  margin-left: 10px;
}

.calendar-wrapper {
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-weight: 100;
  font-size: 20px;
  padding-left: 10px;
  background-color: #ffffff10;
}

.calendar-single-wrapper {
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
  border-radius: 10px;
  border-radius: 10px;
  padding-left: 10px;
  border-color: #ededed;
  position: relative;
  color: white;
  width: 150px;
  float: right;
}

.calendar-icon:hover {
  cursor: pointer;
  -moz-box-shadow: 5px 5px 17px -4px rgba(199, 199, 199, 0.6);
  box-shadow: 5px 5px 17px -4px rgba(199, 199, 199, 0.6);
}

.calendar-overlay {
  position: absolute;
  bottom: 50px;
  right: 0px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #ededed;
  color: black;
}

.bottom-wrapper {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.mode-button-wrapper {
  flex-direction: row;
  display: flex;
}

.mode-button {
  width: 100px;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.3s;
  line-height: 50px;
  background-color: #c9c9c9;
  color: white;
}

.mode-button:hover {
  cursor: pointer;
  background-color: #ffffff70;
}

.default-line-data {
  color: white;
  font-size: 20px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.page-template-container {
  width: 100%;
  height: 100%;
}

.page-template-header {
  height: 10%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 45px;
  font-weight: 100;
  padding-left: 50px;
  padding-bottom: 20px;
  color: white;
}

.page-template-summary {
  height: 20%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  padding-bottom: 10px;
}

.graph-selector {
  padding-bottom: 25px;
  width: 250px;
  height: 100%;
  overflow: scroll;
  max-width: 100%;
  overflow-x: hidden;
}

.page-template-body {
  height: 70%;
  width: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: row;
}
.page-template-footer {
  height: 0%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.lander {
  text-align: center;
  align-items: center;
  justify-content: center;
  /* Full height */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  margin-top: -70px;
}

.lander-text-container {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  z-index: 1;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lander-header-text {
  font-size: 100px;
}

.lander-subheader-text {
  font-size: 30px;
}

.Home .lander p {
  color: #999;
}

.Home {
  height: 100%;
  margin-top: -70px;
}

.Landing {
  text-align: center;
  background-color: white;
}

.Landing-logo {
  height: 40vmin;
}

.Landing-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Landing-link {
  color: #09d3ac;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
    border-radius: 25px;
    padding: 15px;
  }
}

.login-container {
  margin-top: 100px;
  margin: 0 auto;
  max-width: 320px;
}

.login-header {
  width: 100%;
  font-size: 50px;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.no-device-selected-wrapper {
  width: 100%;
  height: 100%;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.no-device-selected-text {
  font-size: 50px;
  padding: 10px;
  font-weight: lighter;
}

.no-device-selected-subtext {
  font-size: 20px;
}

.bodyWrapper {
  position: relative;
  width: 100%;
  float: left;
}

.settings {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 45px;
  height: 45px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  filter: invert(89%) sepia(74%) saturate(968%) hue-rotate(10deg)
    brightness(113%) contrast(105%);
}

.settings:hover {
  transform: rotateZ(45deg);
  cursor: pointer;
}

.hambuger-menu {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 45px;
  height: 45px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}

.no-device-selected-wrapper {
  width: 100%;
  height: 100%;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.no-device-selected-text {
  font-size: 50px;
  padding: 10px;
  font-weight: lighter;
}

.no-device-selected-subtext {
  font-size: 20px;
}

.show {
  opacity: 1;
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.form-subtext {
  font-size: 12px;
  color: #8e8e8e;
}

.header-warning {
  text-align: center;
}

.main-container {
  display: flex;
  flex-direction: row;
  /* height: calc(100% - 50px); */
  height: -webkit-calc(100% - 50px);
  height: -moz-calc(100% - 50px);
  height: -o-calc(100% - 50px);
  height: -ms-calc(100% - 50px);
  height: calc(100% - 50px);
}

.electricity-container {
  padding-top: 20px;
  margin-left: 100px; /*avoid the sidemenu*/
  padding-bottom: 100px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.electricity-paper {
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 30px;
  width: 100%;
}

.electricity-report-small-card-header {
  font-size: 16px;
  font-weight: bold;
}

.electricity-report-small-card-body {
  font-size: 15px;
}

.electricity-report-small-graph-container {
  width: 160px;
  height: 180px;
}

.trh-container {
  padding-top: 20px;
  margin-left: 100px; /*avoid the sidemenu*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.covid-container {
  margin-left: 100px; /*avoid the sidemenu*/
}

.digitalagriculture-container {
  margin-left: 80px; /*avoid the sidemenu*/
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  display:flex;
}

.default-iot-device-page {
  margin-left: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.default-iot-device-page-text {
  width: 300px;
  text-align: center;
  margin-top: 100px;
  font-size: 30px;
}

.sidemenu-container {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  margin-top: 0; /* put the sidemenu container over the navbar, and set height to 100vh to fit entirely to screen*/
  max-width: 75px;
  transition-duration: 0.4s;
  justify-content: space-between;
  height: 100vh;
}

.sidemenu-container-iot-buttons {
  margin-top: 100px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.3);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.sidemenu-button-container {
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  padding: 2px;
}

.sidemenu-button-container:hover {
  cursor: pointer;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.6);
}

.sidemenu-button-label {
  opacity: 0;
  position: absolute;
  left: 75px;
  display: flex;
  align-items: center;
  transition-duration: 0.4s;
  color: black;
  font-size: 15px;
  font-weight: 100;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.6);
  pointer-events: none;
}

.sidemenu-button-container:hover > .sidemenu-button-label {
  opacity: 1;
  pointer-events: visible;
}

.sidemenu-add-button:hover > .sidemenu-button-label {
  opacity: 1;
}

.sidemenu-add-button-container {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.sidemenu-add-button {
  border-radius: 100px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.3);
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition-duration: 0.4s;
  font-size: 25px;
  font-weight: bold;
}

.sidemenu-add-button:hover {
  cursor: pointer;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.6);
}

.sidemenu-button-image {
  width: 50px;
  height: 50px;
}

.sidemenu-button-container-top {
  border-top-right-radius: 15px;
  border-top-width: 0px;
}

.sidemenu-button-container-bottom {
  border-bottom-right-radius: 15px;
}

.sidemenu-button-container-selected-trh {
  background-color: #ffe4e4;
}

.sidemenu-button-container-selected-electricity {
  background-color: #fff7b0;
}

.sidemenu-button-container-selected-water {
  background-color: #a6fcff;
}

.sidemenu-button-container-selected-air {
  background-color: #ffdb9e;
}

.sidemenu-button-container-selected-covid {
  background-color: #ffd2fa;
}

.sidemenu-button-container-selected-digitalAgriculture {
  background-color: #dbffbd;
}

.airpollution-container {
  /*outermost air pollution page div styles*/
  padding-top: 0px;
  margin-left: 100px; /*avoid the sidemenu*/
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 50px);
}

.airpollution-column-container {
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  width: 500px;
}

.airpollution-line-container {
  width: 300px;
  height: 100px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.6);
  padding: 20px;
  border-radius: 15px;
  margin: 15px;
}

.airpollution-devicelist-container {
  width: 350px;
  position: relative;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  margin: 0 0;
  transition-duration: 0.4s;
  justify-content: left;
  float: left;
  overflow-y: scroll;
}

.airpollution-devicelist-container::-webkit-scrollbar {
  display: none;
}

.airpollution-devicelist-container #marker-list {
  min-height: unset;
}

.airpollution-line-map-container {
  width: calc(100% - 350px);
  height: 100%;
  padding-bottom: 0;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.airpollution-container::-webkit-scrollbar {
  display: none;
}

.airpollution-map-container {
  height: 100%;
  width: 100%;
}

#map1 {
  float: left;
  width: 100%;
  height: 100%;
}

#airpollution-chart {
  height: 400px;
  width: 400px;
}

.status-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 30px;
}

.status-card-container {
  margin: 3px;
  border-radius: 5px;
  min-width: 250px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.3);
  opacity: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.status-card-edit-button {
  position: absolute;
  right: 5px;
  top: 5px;
}

.mini-status-card-container {
  max-height: 44px;
  padding: 10px;
  margin: 3px;
  border-radius: 5px;
  width: 250px;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.3);
  opacity: 0.4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition-duration: 0.4s;
}

.status-card-selected {
  opacity: 1;
}

.status-card-container:hover {
  cursor: pointer;
  opacity: 1;
}

.mini-status-card-container:hover {
  cursor: pointer;
  opacity: 1;
}

.status-card-top {
  padding: 10px;
}

.status-card-bottom {
  height: 45px;
  transition-duration: 0.4s;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-card-name {
  font-size: 17px;
}

.status-card-type {
  margin-top: 15px;
}

.status-card-last-seen-date-online {
  background-color: #b0ffb1;
}

.status-card-last-seen-date-offline {
  background-color: #ffb0b0;
}

.statuses-container {
  width: 100%;
}

.statuses-title {
  font-size: 37px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #b8b8b8;
  padding-right: 30px;
  margin-bottom: 15px;
}

.status-home-container {
  margin-left: 100px; /*avoid the sidemenu*/
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.gaugecard {
  padding-top: 0;
  margin-top: -55px;
}

.gaugecard-percentage {
  position: relative;
  z-index: 1;
  top: 35%;
  text-align: center;
  font-size: xx-large;
  font-weight: 400;
}

.gaugecard-metrics {
  font-size: large;
  font-weight: 450;
  padding: 0px;
  padding-top: 1.5em;
  padding-bottom: 0.2em;
  text-align: center;
}

.gaugecard-description {
  font-size: medium;
  font-weight: 200;
  padding: 0px;
  text-align: center;
}

.gaugecard-date {
  font-size: small;
  font-weight: 100;
  text-align: center;
}

.gaugecard-item1 {
  font-size: medium;
  font-weight: 50;
  padding-left: 1.7em;
  padding-top: 2em;
}

.gaugecard-item2 {
  font-size: medium;
  font-weight: 100;
  padding-left: 1.7em;
}

.gaugecard-data {
  font-size: 20px;
  font-weight: 350;
  text-align: center;
}

.side-calendar {
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.linechart-left {
  width: 75%;
  max-width: 800px;
  position: relative;
  justify-content: center;
  text-align: left;
  flex-direction: row;
}

.linechart-calendar {
  flex-direction: row;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 60px;
}

.right-calendar {
  display: flex;
  flex-direction: column;
}

.calendar-selected-date {
  /* position: absolute;
  bottom: 44px;
  right: 10px;*/
  font-size: 28px;
  font-weight: 500;
}

.youvsyou-chart-footer {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.trh-chart-footer {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.trh-footer-buttons-wrapper {
  flex-wrap: wrap;
  width: 80%;
}

.trh-chart-mode-button {
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.1s;
  line-height: 45px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  transition-duration: 0.3s;
}

.trh-chart-mode-button:hover {
  cursor: pointer
}

.youvsyou-chart-button-wrapper {
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 10px #dedede;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  overflow:hidden;
}

.youvsyou-chart-mode-button {
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-duration: 0.1s;
  line-height: 45px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.youvsyou-chart-mode-button:hover {
  cursor: pointer;
}

.refresh-button {
  width: 150px;
  height: 50px;
  background-color: #c9c9c9;
  color: black;
  position: relative;
  left: 15%;
  border-radius: 12px;
}

.mode-button:hover {
  cursor: pointer;
  background-color: #8c8c8c;
  color: white;
}

.reset-button {
  border-radius: 4px;
  height: 30px;
  width: 90px;
  background-color: #c9c9c9;
}

.reset-button:hover {
  background-color: #8c8c8c;
  transition-duration: 0.4s;
}

.more-info-button {
  /* width: 16px;
  height: 16px; */
  /* background: none; */
  background-color: none;
  color: none;
  display: inline-flex;
  border: none;
  outline: none;
  background-image: url("../img/info.png");
  /* display: inline-block; */
}
.more-info-icon {
  width: 25px;
  height: 25px;
}

.electricity-summary-container {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-flex;
}

.electricity-comparison-button {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  background-color: #fff7b0;
}

.comparison-wrapper {
  position: absolute;
  right: 380px;
}

.calendar-right {
  /* display:inline-block */
  /*float: right;*/
  width: 50%;
  padding-left: 10px;
  overflow: scroll;
  max-width: 100%;
  overflow-x: hidden;
}

.calendar-left {
  /* display:inline-block */
  width: 50%;
  height: 100%;
  max-width: 80%;
  overflow-x: hidden;
}

.summary-information-row {
  display: table;
}

.summary-information-column {
  display: table-cell;
}

.compare-table {
  padding-top: 30px;
  margin-left: 200px;
  margin-right: 200px;
}

.electricity-information-card {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 5px;
  box-shadow: 0 0 10px #dedede;
  width: 230px;
  height: 125px;
  font-size: 16px;
}

#medium-bold {
  font-size: 20px;
  font-weight: 400;
}

.electricity-information-cards-wrapper {
  display: flex;
  justify-content: center;
}

.electricity-information-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #ffcc1450 !important;
  color: black;
}

.Range .DayPicker-Day {
  border-radius: 0 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #ffcc14;
  color: black;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #ffcc14;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #ffcc1450;
}

.information-bar-chart {
  width: 400px;
}

.information-bar-chart-wrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.you-vs-container {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.disabled {
  pointer-events: none;
}

.add-form-input {
  padding: 15px;
  height: 79px;
  border-width: 0px;
  box-shadow: 3px 3px 10px -4px rgba(199, 199, 199, 0.6);
  font-size: 18px;
  font-weight: 300;
}

.add-form-modal-body {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
  border-width: 0px;
  min-height: 307px;
}

.add-device-footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.add-device-button {
  width: 100%;
  border-bottom-left-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  padding: 15px;
  transition-duration: 0.2s;
}

.add-device-continue {
  background-color: #fff7b0;
  border-bottom-right-radius: 0.3em;
}

.add-device-continue:hover {
  cursor: pointer;
  background-color: #fff7b080;
}

.add-device-close {
  background-color: #ededed;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.add-device-close:hover {
  cursor: pointer;
  background-color: #d9d9d9;
}

.add-device-header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 34px;
  font-weight: 300;
}

.add-device-modal {
  max-width: 550px;
  min-width: 550px;
}

.add-device-back-button {
  position: absolute;
  left: 30px;
  width: 50px;
  height: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
  transition-duration: 0.4s;
  font-size: 35px;
}

.add-device-back-button:hover {
  cursor: pointer;
  font-size: 50px;
}

.survey-electricity-title {
  font-size: 24px;
  padding-bottom: 20px;
}

.mini-status-circle {
  width: 12px;
  height: 12px;
  border-radius: 20px;
}

/* Electricity Home Component Styles Below */

.electricity-home-container {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.recent-stats-information-box {
  display: inline-flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  max-height: 500px;
}

.recent-stats-information-card {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 15px;
  box-shadow: 0 0 10px #dedede;
  font-size: 15px;
  height: 150px;
  width: 250px;
}

.recent-stats-comparison-circle {
  --name: "None";
  --radius: 20px;
  --display: "-";
  margin-right: 1em;
  border-radius: 50%;
  width: var(--radius);
  height: var(--radius);  
  background-color: var(--color, silver);
  font-size: small;
  text-align: center;
  display: inline-flex; /* or inline-flex */
  align-items: center; 
  justify-content: center;
  transition: 0.5s width, 0.5s height;
}

.tips-header {
  font-size: 30px;
  padding-bottom: 5px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  height: 40%;
  justify-content: center;
  align-items: center;
  display:flex;
}

.tip {
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
}

.tips {
  font-size: 17px;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
  display:flex;
}

.tips-wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}

.tips-container {
  background-color: #ffe7b0;
  padding: 5px;
  border-radius: 10px;
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 50%;
  height: auto;
  top:50%;
  left:50%;
  padding-left: 20px;
  transform: translate(-50%,-50%);
  border-radius: 20px;
  border: 2px solid #ffcc14;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.electricity-device-container {
  align-items: left;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.electricity-device-rectangle {
  --device_name: "Default Device";
  background-color: var(--bg_color, silver);
  width: var(--width, 400px);
  height: var(--height, 200px);
  font-size: medium;
  text-align: center;
  display: inline-flex;
  align-items: center; 
  justify-content: center;
  opacity: 80%;

  padding: 15px;
  margin: 15px;
  box-shadow: 0 0 10px #dedede;

  display: inline-flex;
  font-size: 15px;
}
.electricity-device-rectangle:hover {
  opacity: 100%;
  cursor: context-menu;
}

.goal-progress-summary-chart {
  width: 450px;
  
}

.bar-chart-wrapper {
  width: 100%;
  position: relative;
}

.goal-panel-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

.goal-panel-circular-progress-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.goal-panel-circular-progress-trophy {
  position: absolute;
  width: 110px;
  top: 20px;
  left: 20px;
}

.goal-panel-circular-progress-text {
  text-align: center;
  padding-top: 10px;
}

.custom-horizontal {
  display: flex;
  flex-direction: row;
}

.custom-center {
  justify-content: center;
  align-items: center;
}

/* Electricity Home Component Styles Above */

.calendar-wrapper-container {
  margin-bottom: 10px;
}

.calendar-wrapper-container:hover {
  cursor: pointer;
}

.calendar-wrapper-header {
  background-color: rgb(255, 204, 20);
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  width: 150px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.calendar-wrapper-title {
  font-size: 12px;
  text-align: center;
}

.calendar-wrapper-date {
  font-size: 17px;
  text-align: center;
}

.calendar-wrapper-child-closed {
  overflow: hidden;
  transition-duration: 0.5s;
  height: 0px;
}

.calendar-wrapper-child-open {
  overflow: hidden;
  height: 300px;
  transition-duration: 0.5s;
}

.you-vs-header {
  font-size: 37px;
  padding-bottom: 20px;
}