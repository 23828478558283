.letterStyle{
    font-Size:20px;   
}
.blankrectangle{
    width:650px;
    height:500px;
    font-size: 20px;
    margin-top:10%;
    margin-left:10%;
    background-color:transparent;
    border:solid;
    border-color: black;
}

.buttonfield{
    width:650px;
    height:80px;
    font-size:16px;
    margin-top:3%;
    margin-left:10%;
    border:solid;
    border-color:transparent;   
}

.textfield{
    font-size:16px;
    margin-top:1%;
    margin-left:11%; 
}

.ag-rowcontainer{
    width: 100%;
    margin-left: 80px; /*avoid the sidemenu*/
    flex-direction: row;
    flex-wrap: wrap;
    display:flex;
}

.linechart {
    display: inline-block;
    width: 650px;
    /* height: 80%; */
    position: relative;
    justify-content: center;
    text-align: left;
    flex-direction: row;
    margin-left: 65px;
  }

.textCenter{
    text-align: center;
}

.editModal{
    
    background-color: "white";
    opacity: 1;
    border-radius: "10px";
    border-width: "0px";
}

