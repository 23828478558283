.light-box {
  height: 20px;
  width: 25%;
  margin: 6px 0;
  float: left;
}
.light-green {
  margin: 0 auto;
  width: 16px;
  height: 16px;
  background-color: #ABFF00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 8px;
}
.light-red {
  margin: 0 auto;
  width: 16px;
  height: 16px;
  background-color: #F00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 8px;
}