.add-device-type-container {
  margin: 10px;
  padding: 10px;
  width: 175px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition-duration: 0.2s;
}

.add-device-type-wrapper {
  display: grid;
  grid-template-columns: 50% 10px;
  grid-template-rows: 100px;
}

.add-device-type-container:hover {
  cursor: pointer;
  box-shadow: 1px 0px 10px -1px rgba(100, 100, 100, 0.3);
  transition-duration: 0.2s;
}