body {
    margin: 0px;
  }
  
  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }
  
  .legend {
    text-align: left;
    line-height: 18px;
    color: #555;
  }
  
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }

  #info-card {
    position: relative;
    width: 100%;
    height: auto;
    background-color: white;

  }

  .active.tab {
    height: 200px;
  }

  #hide-display-button {
    width: 200px;
  }

  p h3 {
    cursor: text;
  }

  .new-line {
    display: block;
  }

  .date-input {
    display: block;
    width: 150px;
    float: left;
  }

  .time-input {
    margin-left: 15px;
    width: 120px;
  }

  .DayPicker-Day--highlighted {
    background-color: orange;
    color: white;
  }

  .simple-line-chart {
    overflow-x: scroll;
  }

  .control-segment .title {
    cursor: pointer;
    margin: 0;
    padding: 5px 14px;
    font-weight: normal;
  }

  .control-segment .title:hover {
    transition: 0.3s ease-in;
    background-color: rgba(226, 226, 226, 0.705);
  }

  .control-segment .title:active {
    background-color: #ffdb9e;
    transition: 0s;
  }

  .control-segment .title h5 {
    display: inline;
    font-weight: normal;
  }

  .control-segment .expandable {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in;
  }

  .control-segment .expandable .content {
    margin-bottom: 10px;
  }

  .control-segment .expand {
    max-height: 1000px;
    transition: max-height 0.3s ease-in;
  }

  .control-options.ui.segment {
    padding: 0;
  }

  .red-border {
    border: 1px solid red;
    border-radius: 5px;
  }

  .playback-control .header {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin: 0;
    padding: 10px 0;
  }

  .playback-control .btn-group {
    height: fit-content;
    position: relative;
    margin-top: 20px;
    height: 40px;
    bottom: 0;
  }

  .playback-control .btn-group button {
    height: fit-content;
  }

  .playback-control .warning {
    position: relative;
    display: inline;
    color: red;
    font-size: x-small;
    bottom: 0;
  }

  