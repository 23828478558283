.time-picker {
    display: flex;
    justify-content: space-around;
    width: auto;
    height: auto;
    margin: 0;
}

.time-picker .angle {
    margin: 0;
    padding: 0;
}


.time-picker .colon span {
    position: relative;
    top: 35%;
    transform: translateY(-50%);
    height: fit-content;
}

.single-picker {
    text-align: center;
    padding: 0 5px;
}

.single-picker .up {
    cursor: pointer;
    margin-left: 50%;
    transform: translateX(-50%);
}

.single-picker span {
    display: block;
    text-align: center;
}

.time-picker div:nth-child(4) .down {
    margin-left: 2px;
}

.single-picker .down {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.ampm-picker {
    text-align: center;
}

